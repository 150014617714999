<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <form method="post" @submit.prevent="onSubmit('frmBooking')" data-vv-scope="frmBooking" novalidate="novalidate">
      <div class="row justify-content-md-center">
        <div class="col-12 col-md-12">
          <div class="card card-fullheight">
            <div class="card-body">
              <h5 class="box-title mb-3">{{ $t('booking.new-edit_request') }}</h5>

              <!-- Operação, Origem e Destino -->
              <div class="row custom-row">
                <!--  Operação  -->
                <div class="col-lg-4 col-md-6 form-group px-2 mt-2">
                  <dual-switch
                    v-bind:options="operationOptions"
                    v-bind:selectedItem="booking.tipoOperacao"
                    v-on:set-option="setOperation"
                    :disabled="processInformed"/>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.origem') || errors.first('booking.destino')"
                      class="royalc-error-field">&nbsp;</small>
                  </transition>
                </div>

                <!--  Origem  -->
                <div class="col-lg-4 col-md-6 form-group px-2">
                  <label class="small grey-label">{{  $t('form.proposal.origin')  }}</label>
                  <v-select class="grey-field" name="value.origin" label="nome" :clearable="false" :searchable="true"
                    v-model="booking.origem" :options="originList" @search="originFetchOptions" :disabled="processInformed"
                    :filter-by="originDestinationFilter" v-validate="{ required_object: true }">
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar uma origem
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma origem encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('value.origin')" class="royalc-error-field">{{ errors.first('value.origin') }}</small>
                  </transition>
                </div>

                <!--  Destino  -->
                <div class="col-lg-4 col-md-6 form-group px-2">
                  <label class="small grey-label">{{  $t('form.proposal.destination')  }}</label>
                  <v-select class="grey-field" name="value.destination" label="nome"  :clearable="false" :searchable="true"
                    v-model="booking.destino"  :options="destinationList" @search="destinationFetchOptions" :disabled="processInformed"
                    :filter-by="originDestinationFilter" v-validate="{ required_object: true }"
                  >
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um destino
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum destino encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }} {{ (option.sigla) ? '(' + option.sigla + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('value.destination')" class="royalc-error-field">{{ errors.first('value.destination') }}</small>
                  </transition>
                </div>

              <!-- Equipamentos, Mercadoria e Situação -->
                <!--  Equipamentos  -->
                <div class="col-lg-4 col-md-6 form-group px-2">
                  <label class="small grey-label">{{  $t('form.proposal.equipments')  }}</label>
                  <search-bar-quantity name="booking.equipamentos" field-name="descricao"
                    v-model="booking.equipamentos" :options="equipmentList" :disabled="processInformed"/>
                  <transition name="slide" mode="">
                    <small v-if="equipamentsError" class="royalc-error-field">Campo é obrigatório</small>
                  </transition>
                </div>

                <!--  Mercadoria  -->
                <div class="col-lg-4 col-md-6 form-group px-2">
                  <label class="small grey-label">Mercadoria</label>
                  <v-select name="booking.mercadoria" class="grey-field" label="nome" :clearable="false" :searchable="true"
                    :filter-by="nomeFilterBy" v-model="booking.mercadoria" :options="commoditieList"
                    @search="commoditieFetchOptions" v-validate="{ required_object: true }" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search }">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar uma mercadoria
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhuma mercadoria encontrada para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.commoditie')" class="royalc-error-field">{{ errors.first('booking.commoditie') }}</small>
                  </transition>
                </div>

              <!-- Número do Booking, Solicitante e Vendedor -->
                <!--  Seller  -->
                <div class="col-lg-4 col-md-6 form-group px-2">
                  <label class="small grey-label">Vendedor</label>
                  <v-select class="grey-field" name="booking.vendedor" label="nome" :clearable="false"
                    :searchable="true" v-model="booking.vendedor" :options="sellerList"
                    @search="sellerFetchOptions" :disabled="processInformed">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um vendedor
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum vendedor encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.vendedor')" class="royalc-error-field">{{ errors.first('booking.vendedor') }}</small>
                  </transition>
                </div>

              <!-- Cliente e Processo -->
                <!--  Processo  -->
                <div class="col-lg-4 col-md-6 form-group px-2">
                  <label class="small grey-label">Processo</label>
                  <v-select class="grey-field" name="booking.processo" label="name" :clearable="true"
                    :searchable="true" v-model="booking.processo" :options="processList"
                    @search="processFetchOptions" @input="updateProcessFields" :filter-by="filterBy">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um processo
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum processo encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.processo')" class="royalc-error-field">{{ errors.first('booking.processo') }}</small>
                  </transition>
                </div>

                <!--  Customer  -->
                <div class="col-lg-8 col-md-6 form-group px-2">
                  <label class="small grey-label">{{  $t('form.proposal.customer')  }}</label>
                  <v-select class="grey-field" name="booking.cliente" label="name" :clearable="true"
                    :searchable="true" v-model="booking.cliente" :options="customerList"
                    :disabled="processInformed" @search="customerFetchOptions">
                    <template slot="no-options" slot-scope="{searching, search}">
                      <em v-if="!searching" style="opacity: 0.5;">
                        Digite para buscar um cliente
                      </em>
                      <em v-else style="opacity: 0.5;">
                        Nenhum cliente encontrado para {{ search }}
                      </em>
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.name }} {{ (option.cnpj) ? '(' + option.cnpj + ')' : null }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }} {{ (option.cnpj) ? '(' + option.cnpj + ')' : null }}
                      </div>
                    </template>
                  </v-select>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('booking.cliente')" class="royalc-error-field">{{ errors.first('booking.cliente') }}</small>
                  </transition>
                </div>
              </div>

              <!-- Observação -->
              <div class="row cutom-row">
                <div class="col-md-12 mt-4 px-2">
                  <label class="small grey-label mb-0">Observação</label>
                  <custom-textarea
                    v-model="booking.observacao"
                    name="booking.observacao"
                    type="text"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control mt-0"
                    :rows="3"
                    v-validate="{ required: false }"
                    :error="errors.first('booking.observacao')">
                  </custom-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Submit -->
        <div class="col-12 mb-5">
          <button type="button" class="btn btn btn-outline-light pull-right" @click="onSave">
            Salvar
          </button>
          <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onCancel">
            Cancelar
          </button>
          <!-- <button type="button" class="btn btn-link btn-sm mt-1 pull-right" @click="onShow">
            Console
          </button> -->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
// Components
import DualSwitch from '../../components/Forms/DualSwitch.vue'
import SearchBarQuantity from '../Proposal/SearchBarQuantity.vue'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// Form
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// vue-select
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import EmployeeService from '@/services/EmployeeService'
import BookingService from '@/services/BookingService'
import OriginDestinationService from '@/services/OriginDestinationService'
import CommoditieService from '@/services/CommoditieService'
import ProposalService from '@/services/ProposalService'
import EquipmentService from '@/services/EquipmentService'
import ProcessService from '@/services/Process/ProcessService'

export default {
  name: 'BookingForm',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('booking.new-edit_booking') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: true,
      isEdit: false,
      formErrors: {},
      fullPage: true,
      submitted: false,
      timer: null,
      delay: 400,
      //  Selected Values (Form)
      booking: {
        armador: null,
        cliente: null,
        vendedor: null,
        solicitante: null,
        processo: null,
        tipoOperacao: {
          id: 2,
          name: 'IMPORT'
        },
        origem: null,
        destino: null,
        navio: null,
        mercadoria: null,
        equipamentos: [],
        numero: null,
        situacao: {
          id: 0,
          label: 'Solicitar'
        },
        etd: null,
        ddl: null,
        possuiMulta: null,
        valorMulta: null,
        observacao: null,
        dataCriacao: null,
        dataSolicitacao: null,
        send_email: 0
      },
      // List of options to select on select input
      operationOptions: [
        {
          id: 2,
          name: 'IMPORT'
        },
        {
          id: 1,
          name: 'EXPORT'
        }
      ],
      bookingList: [],
      statusList: [],
      employeeList: [],
      sellerList: [],
      originList: [],
      processList: [],
      customerList: [],
      destinationList: [],
      commoditieList: [],
      shipOwnerList: [],
      vesselList: [],
      equipmentList: []
    }
  },
  components: {
    Loading,
    vSelect,
    SearchBarQuantity,
    CustomTextarea,
    DualSwitch
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {
    this.$store.dispatch('SetShowFilter', false)
    this.$store.dispatch('setShowSearch', false)
  },
  created () {
    let _this = this
    _this.isEdit = _this.$route.name === 'BookingEdit'
    let bookingId = this.$route.params.id

    let filters = {
      'searchTerm': '',
      'per_page': 500,
      'includeAlan': true
    }

    // Load basilares data
    CommoditieService.getCommodities(filters).then(res => {
      _this.commoditieList = res.data.data
      filters.per_page = 100
      ProposalService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
        BookingService.getShipOwners(filters).then(res => {
          _this.shipOwnerList = res.data.data
        })
      })
    })

    EmployeeService.getOptions(filters).then(res => {
      _this.employeeList = res.data.data
    })

    EmployeeService.getSellers().then(res => {
      _this.sellerList = res.data.data
    })

    _this.equipmentLoadOptions().then(res => {
      _this.equipmentList = res
    })

    if (bookingId) {
      BookingService.getBooking(bookingId).then(res => {
        let data = res.data.data
        let edit = this.booking

        edit.origem = {
          id: data.id_porto_origem.id,
          nome: data.id_porto_origem.nome,
          pais: data.id_porto_origem.id_pais.nome,
          sigla: data.id_porto_origem.sigla
        }

        edit.destino = {
          id: data.id_porto_destino.id,
          nome: data.id_porto_destino.nome,
          pais: data.id_porto_destino.id_pais.nome,
          sigla: data.id_porto_destino.sigla
        }

        if (this.$util.isset(data.id_mercadoria)) {
          edit.mercadoria = {
            id: data.id_mercadoria.id,
            nome: data.id_mercadoria.nome_portal
          }
        }

        edit.situacao = this.statusList.find(x => parseInt(x.id) === data.situacao)

        if (this.$util.isset(data.idcompanhia_transporte)) {
          edit.armador = {
            id: data.idcompanhia_transporte.id,
            id_table_origin: data.idcompanhia_transporte.id_table_origin,
            name: data.idcompanhia_transporte.nome
          }
        }

        if (this.$util.isset(data.id_navio)) {
          edit.navio = {
            id: data.id_navio.id,
            id_table_origin: data.id_navio.id_table_origin,
            name: data.id_navio.nome
          }
        }

        edit.numero = data.numero

        if (this.$util.isset(data.id_solicitante)) {
          edit.solicitante = {
            id: data.id_solicitante.id,
            id_table_origin: data.id_solicitante.id_table_origin,
            nome: data.id_solicitante.id_pessoa.nome
          }
        }

        if (this.$util.isset(data.id_vendedor)) {
          edit.vendedor = {
            id: data.id_vendedor.id,
            id_table_origin: data.id_vendedor.id_table_origin,
            nome: data.id_vendedor.id_pessoa.nome
          }
        }

        if (this.$util.isset(data.id_cliente)) {
          edit.cliente = {
            id: data.id_cliente.id,
            id_table_origin: data.id_cliente.id_table_origin,
            name: data.id_cliente.idpessoa.nome
          }
        }

        if (this.$util.isset(data.dead_line)) {
          edit.ddl = data.dead_line
        }

        edit.etd = data.etd
        edit.dataSolicitacao = data.data_solicitacao
        edit.possuiMulta = data.possui_multa
        edit.valorMulta = data.valor_multa
        edit.observacao = data.observacao

        this.booking = edit
      })
    }

    _this.isLoading = false
  },
  methods: {
    displayErrors (errors) {
      let formatedErrors = util.getFormErrors(errors)
      this.formErrors = formatedErrors
    },
    onShow () {
    },
    showNumber (number) {
      return util.numberFormat(number, 2, ',', '.')
    },
    onSelectEmployee (val) {
      this.Booking.employee = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_Booking: object.id } }).href
    },
    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      EmployeeService.getEmployees(filters).then(res => {
        me.employeeList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the currency search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    sellerFetchOptions (search, loading) {
      let me = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      EmployeeService.getSellers(filters).then(res => {
        me.sellerList = res.data.data
        loading(false)
      })
    },
    nomeFilterBy (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    customerFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      ProposalService.getCustomers(filters).then(res => {
        _this.customerList = res.data.data
        loading(false)
      })
    },
    originDestinationFilter (option, label, search) {
      let temp = search.toLowerCase()

      return (this.$util.slugify(option.nome).toLowerCase().indexOf(this.$util.slugify(temp)) > -1 || this.$util.slugify(option.sigla).toLowerCase().indexOf(this.$util.slugify(temp)) > -1)
    },
    /**
     * Triggered when the origin search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    originFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'operation': _this.booking.tipoOperacao.id,
        'searchTerm': search
      }

      OriginDestinationService.getOriginsByOperation(filters).then(res => {
        _this.originList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the destination search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    destinationFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'operation': _this.booking.tipoOperacao.id,
        'searchTerm': search
      }

      OriginDestinationService.getDestinationsByOperation(filters).then(res => {
        _this.destinationList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the commoditie search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    commoditieFetchOptions (search, loading) {
      let _this = this
      loading(true)
      let filters = {
        'searchTerm': search
      }

      CommoditieService.getCommodities(filters).then(res => {
        _this.commoditieList = res.data.data
        loading(false)
      })
    },
    onCancel (e) {
      var _this = this
      _this.$router.push({ name: 'BookingIndex' })
    },
    onSave (e) {
      var _this = this
      this.submitted = true
      _this.$validator.validate().then(valid => {
        if (valid) {
          _this.isLoading = true

          if (this.$router.currentRoute.name === 'BookingEdit') {
            this.booking.edit = 'true'
          }

          BookingService.postNewRequest(this.booking).then(response => {
            _this.$router.push({ name: 'BookingIndex' })
          }).catch(err => {
            if (err.code === 400) {
              _this.displayErrors(err.errors)
            }
            _this.isLoading = false
          })
        }
      })
    },
    setOperation (obj) {
      if (this.booking.tipoOperacao.id !== obj.id) {
        this.booking.origem = {
          id: null,
          nome: null
        }
        this.booking.destino = {
          id: null,
          nome: null
        }
      }
      this.booking.tipoOperacao = obj
    },
    equipmentLoadOptions () {
      // let _this = this

      let promise = new Promise((resolve, reject) => {
        let filters = {
          'modality': 2, // marítimo
          'quotations': true
        }

        EquipmentService.getEquipments(filters).then(res => {
          let items = []

          for (let i in res.data.data) {
            let item = res.data.data[i]

            item.quantity = 0

            // for (let sI in _this.filterData.equipments) {
            //   let selected = _this.filterData.equipments[sI]

            //   if (selected.id === item.id) {
            //     item.quantity = selected.quantity
            //     break
            //   }
            // }
            items.push(item)
          }
          resolve(items)
        }).catch((error) => {
          reject(error)
        })
      })

      return promise
    },
    /**
     * Triggered when the process search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    processFetchOptions (search, loading) {
      if (search.length > 0) {
        window.clearTimeout(this.timer)

        this.timer = window.setTimeout(() => {
          loading(true)

          let filters = {
            'searchTerm': search,
            'operation': [this.booking.tipoOperacao.id],
            'modal': [2] // Extranet >> Enums/Modal::MARITIMO
          }

          ProcessService.getOptions(filters).then(res => {
            this.processList = res.data.data
            loading(false)
          })
        }, this.delay)
      }
    },
    updateProcessFields () {
      if (!this.booking.processo) {
        return
      }

      this.loadSelectedProcessEquipment()

      this.booking.cliente = {
        id: this.booking.processo.customer_id,
        cnpj: this.booking.processo.customer_cnpj,
        name: this.booking.processo.customer_name
      }

      this.booking.vendedor = {
        id: this.booking.processo.seller_id,
        nome: this.booking.processo.seller_name
      }

      this.booking.tipoOperacao = this.operationOptions.find(x => x.id === this.booking.processo.operation)

      this.booking.origem = {
        id: this.booking.processo.origin_id,
        nome: this.booking.processo.origin_name
      }

      this.booking.destino = {
        id: this.booking.processo.destin_id,
        nome: this.booking.processo.destin_name
      }

      this.booking.mercadoria = {
        id: this.booking.processo.commodity_id,
        nome: this.booking.processo.commodity_name
      }
    },
    loadSelectedProcessEquipment () {
      this.isLoading = true

      if (this.booking.equipamentos.length > 0) {
        this.booking.equipamentos.forEach(equip => {
          equip.quantity = 0
        })
      }

      ProcessService.getProcessEquipments(this.booking.processo.id).then(res => {
        let equipmentList = res.data.data
        this.booking.equipamentos = equipmentList.map(x => {
          return {
            id: x.id,
            descricao: x.description,
            description: x.description,
            quantity: x.quantity
          }
        })
        this.isLoading = false
      })
    },
    filterBy (option, label, search) {
      let splited = search.toLocaleLowerCase().split(' ')
      let lowerLabel = label.toLocaleLowerCase()

      return splited.reduce((founded, term) => {
        founded += lowerLabel.indexOf(term) > -1
        return founded
      })
    }
  },
  computed: {
    equipamentsError () {
      return this.submitted && this.booking.equipamentos.length <= 0
    },
    processInformed () {
      return this.booking.processo != null
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .v-select.md-form-control {
    height: 35px;

  }

  td.editable {
    padding: 0;
    vertical-align: middle;
  }

  td.editable input {
    background-color: inherit;
    border: 0;
    width: 100%;
    min-height: 33px;
    box-sizing: border-box;
  }

  .card-subtitle {
    margin-top: 15px;
  }

</style>
